<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col-8">
            <Panel type="board">
              <template v-slot:headline>検索して対象を指定</template>
              <template v-slot:body>
                <div class="search">
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>お名前</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="name"
                                  v-trim
                                  v-maxlength
                                  maxlength="41"
                                  v-model="searchForm.name"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>メールアドレス</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="email"
                                  v-email
                                  v-trim
                                  v-maxlength
                                  maxlength="50"
                                  v-model="searchForm.email"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row" v-if="!isClientChild">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>電話番号</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="tel"
                                  name="telephone"
                                  v-trim
                                  v-number
                                  v-maxlength
                                  maxlength="14"
                                  v-model="searchForm.telephone"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>user ID</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="number"
                                  v-trim
                                  v-model="searchForm.number"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:contentFooter>
                <ul class="listGrid justify-content-end">
                  <li class="listGrid-item">
                    <button class="btn btn-white" type="button" @click="resetSearchManual">リセット</button>
                  </li>
                  <li class="listGrid-item">
                    <button class="btn btn-main" type="button" @click="submitSearchManual">検索</button>
                  </li>
                </ul>
              </template>
            </Panel>
          </div>
          <div class="col-4">
            <Panel type="board" :padding="false" :center="true">
              <template v-slot:headline>CSVで対象を指定</template>
              <template v-slot:body>
                <div class="d-flex justify-content-center align-items-center">
                  <ActionButton class="btn btn-lg btn-black" :disabled="isUploadCsvDisabled" :handle-submit="uploadCsvManual" button-text="CSVアップロード" />
                  <input
                    type="file"
                    style="display: none"
                    ref="csvInput"
                    accept=".csv"
                    @change="onCsvFilePickedManual"
                  />
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    :checkedKey="checkedKey"
                    :data-table="dataTable"
                    :labels="labels"
                    @select-items="handleSelectedItems"
                    :count="customerCount"
                    ref="table"
                  />
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  ref="pagination"
                  :listLength="customerCount"
                  :modulePath="customerModulePath"
                  :searchConditions="searchConditions"
                  :subdomain="subdomain"
                />
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item pos-end">
            <ul class="listGrid">
              <li class="listGrid-item">
                <button class="btn btn-red" type="button" :disabled="isPaymentOrChargeDisabled" @click="handeClickShowModal(paymentType)">消費する</button>
              </li>
              <li class="listGrid-item">
                <button class="btn btn-main" type="button" :disabled="isPaymentOrChargeDisabled" @click="handeClickShowModal(chargeType)">付与する</button>
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <ManualPointModal
      ref="modal"
      :type='type'
      :pointStore="pointStore"
      :pointMaster="pointMaster"
      :target='selectedUsers'
      :searchConditions="searchForm"
      :totalUserSelected="totalUserSelected"
      @checkImportManualStatus="checkImportManualStatus"
      @setImportingCsv="setImportingCsv"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
//mixin
import flatpickr from '@/mixins/plugin/flatpickr';
import sort from '@/mixins/plugin/sort';
import search from '@/mixins/plugin/search';
import download from '@/mixins/plugin/download';
import pickFileCsv from '@/mixins/plugin/pickFileCsv';
import nav from '@/mixins/nav/point';
//component
import FormRow from '@/components/FormRow.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import ManualPointModal from '@/views/Event/Point/Management/Form.vue';
//helpers
import { formatLocalString, formatDateAndTime, breakLineWithComma, endline } from '@/helpers/formatData';
//constants
import { EventConstants } from '@/constants/event';
import { StatusConstants } from '@/constants/status';

export default {
  data: function() {
    return {
      pageName: 'ポイント',
      customerModulePath: 'customer/getCustomerList',
      searchConditions: {},
      searchForm: {},
      searchFields: ['name', 'telephone', 'email', 'number'],
      checkedKey: 'numberPut',
      selectedUsers: {
        allFlag: false,
        ids: [],
      },
      type: '',
      isImportDisabled: false,
      paymentType: StatusConstants.currencyType.payment,
      chargeType: StatusConstants.currencyType.charge,
      pointStore: {},
      pointMaster: null,
      importingItem: 'IMPORTING_MANUAL_POINT',
      importStatusAction: 'currency/getImportStatus',
      isFirstLoaded: true,
      timerId: null
    };
  },
  components: {
    FormRow,
    TableWithCheckbox,
    PaginationLog,
    ManualPointModal
  },
  mixins: [nav, flatpickr, sort, search, download, pickFileCsv],
  computed: {
    ...mapGetters({
      userInfo: 'auth/infor',
      isClientChild: 'auth/isClientChild',
      customerList: 'customer/customerList',
      customerCount: 'customer/customerCount',
      childEventDetail: 'event/childEventDetail',
      parentEventDetail: 'event/parentEventDetail',
      isPublicPointFlag: 'event/isPublicPointFlag',
      hasSmsParentEvent: 'event/hasSmsParentEvent',
    }),
    subdomain() {
      return this.$route.params.subdomain;
    },
    initialSearchConditions() {
      return { validFlag: true, ...(!this.isPointCommon && { directory: this.$route.params.directory}) };
    },
    dataTable() {
      return this.customerList.map(item => {
        return {
          ...item,
          number: item?.hasSwitchCpm ? breakLineWithComma((item?.number ? (item.number + ',') : '') + item?.numberPut) : item?.isCpm ? item?.number : item?.numberPut,
          name: endline(item?.nameSei + ' ' + item?.nameMei, 21),
          email: !this.hasSmsParentEvent ? item?.email : (item?.email && item?.mobilephone ? breakLineWithComma(item.email + ',' + item?.mobilephone) : (item?.email || item?.mobilephone)),
          ownPoint: formatLocalString(item.point) + 'P',
          loginDate: formatDateAndTime(item.loginDate),
        };
      });
    },
    isPaymentOrChargeDisabled() {
      const disabledStatusList = [
        EventConstants.status.unregistered.value,
        EventConstants.status.preparingPublic.value,
        EventConstants.status.expired.value,
        EventConstants.status.paused.value,
      ];
      return this.checkEventStatus(disabledStatusList) || this.isImportDisabled || !this.isPublicPointFlag;
    },
    isUploadCsvDisabled() {
      const disabledStatusList = [
        EventConstants.status.preparingPublic.value,
        EventConstants.status.expired.value,
        EventConstants.status.paused.value,
      ];
      return this.selectedUsers.allFlag || !!this.selectedUsers.ids.length || this.isImportDisabled || this.checkEventStatus(disabledStatusList) || !this.isPublicPointFlag;
    },
    totalUserSelected() {
      return this.selectedUsers?.count || 0;
    },
    importCsvAction() {
      return `${this.storeModule}/importManualCsv`;
    },
    currencyId() {
      return this.isPointCommon ? this.parentEventDetail?.id : this.childEventDetail?.id;
    },
    labels() {
      return [
        { isCheckbox: true },
        { key: 'number', name: 'user ID', tdClass: 'pre-line' },
        { key: 'name', name: 'お名前' },
        { key: 'email', name: this.hasSmsParentEvent ? 'メールアドレス/携帯電話番号' : 'メールアドレス', tdClass: 'pre-line' },
        { key: 'loginDate', name: '最終ログイン' },
        { key: 'ownPoint', name: '保有ポイント' },
      ];
    }
  },
  watch: {
    isPublicPointFlag(val) {
      if (this.isFirstLoaded && this.currencyId && !val) {
        this.$message.showError('notChargeOrPaymentPoint');
        this.isFirstLoaded = false;
      }
    },
  },
  mounted() {
    const importingId = (JSON.parse(localStorage.getItem(this.importingItem)) || {})[this.importPath];
    if (!importingId) {
      const loading = this.$loading.show();
      this.initForm();
      Promise.all([
        this.checkCanUseCurrency()
      ]).finally(() => {
        this.timerId = setInterval(() => this.checkCanUseCurrency(), 5000);
        this.$loading.clear(loading);
      });
    }
  },
  methods: {
    async checkCanUseCurrency() {
      let result = await this.$store.dispatch('currency/checkCanUseCurrency', this.currencyId);
      if (!result) {
        this.isImportDisabled = true;
      } else {
        clearInterval(this.timerId);
        this.isImportDisabled = false;
      }
    },
    handeClickShowModal(key) {
      if (this.selectedUsers.allFlag || this.selectedUsers.ids.length > 0) {
        this.type = key;
        this.$refs.modal.showModal('manualPointModal');
      } else {
        this.$message.noSelectedItem();
      }
    },
    handleSelectedItems(items) {
      this.selectedUsers = items;
      if (this.selectedUsers.count === 1) {
        this.pointStore = this.getDataItem('pointStore');
        this.pointMaster = this.getDataItem('pointMaster');
      }
    },
    getDataItem(item) {
      return this.customerList?.find(e =>
        this.selectedUsers.allFlag || e.numberPut === this.selectedUsers['ids'][0]
      )?.[item];
    },
    resetSearchManual() {
      this.resetSearch();
      this.resetCheckbox();
    },
    submitSearchManual() {
      this.search();
      this.resetCheckbox();
    },
    uploadCsvManual() {
      if (!this.isUploadCsvDisabled) {
        this.uploadCsv();
      }
    },
    onCsvFilePickedManual(event) {
      if (!this.isUploadCsvDisabled) {
        this.onCsvFilePicked(event, 'noPaymentedOrChargedManualPoint', null, true, 'currency_csv');
      }
    },
    checkImportManualStatus(id, manualType, cancelType) {
      this.checkImportStatus(id, manualType || 'currency_csv', true, true, cancelType);
    },
    checkEventStatus(statusList) {
      return this.isPointCommon ? !this.parentEventDetail?.canUseManualPoint: statusList.includes(this.childEventDetail?.status?.id);
    },
    resetCheckbox() {
      if (this.totalUserSelected) {
        this.$refs.table.resetCheck();
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.statusTimer);
  },
};
</script>
